import React, { useCallback, useState } from 'react';
import {
  Map,
  Pane,
  Polygon,
  Polyline,
  Tooltip,
  CircleMarker
} from 'react-leaflet';
import { DomEvent } from 'leaflet';
import AnimNumber from './AnimNumber';
import { mapColor } from './config';
import 'leaflet/dist/leaflet.css';
import './DistMap.css';

const NineSegs = [
  [
    [23.467724, 122.506622],
    [24.572216, 122.788742]
  ],
  [
    [20.826548, 121.174026],
    [21.697515, 121.91169]
  ],
  [
    [19.030963, 120.003662],
    [18.020528, 119.476318]
  ],
  [
    [16.024696, 119.069824],
    [15.040296, 119.058838]
  ],
  [
    [11.953349, 118.981934],
    [10.90883, 118.531494]
  ],
  [
    [7.993957, 116.235352],
    [7.188101, 115.554199]
  ],
  [
    [3.743671, 112.851563],
    [3.414725, 111.785889]
  ],
  [
    [5.998533, 108.303223],
    [7.08999, 108.226318]
  ],
  [
    [11.264612, 110.061035],
    [12.264864, 110.313721]
  ],
  [
    [15.178181, 109.852295],
    [16.214675, 109.302979]
  ]
];

function getColor(n) {
  let i = 0;
  while (n > mapColor[i][0]) {
    i++;
  }
  return mapColor[i][1];
}

const Noop = () => {};

function DistMap({
  data,
  bbox = [
    [4, 73],
    [54, 135]
  ],
  selected,
  style,
  onClick = Noop
}) {
  const [showNumber, setShowNumber] = useState(false);
  const toggleShowNumberHandler = useCallback(() => {
    setShowNumber(prev => !prev);
  }, []);
  const mapClickHandler = useCallback(() => {
    onClick('');
  }, [onClick]);
  const featureClickHandler = useCallback(
    ev => {
      DomEvent.stop(ev);
      onClick(ev.target.options.adcode);
    },
    [onClick]
  );
  return (
    <div className="distMap">
      <div className="legend">
        <div className="block">
          {mapColor.map(c => (
            <span key={c[0]} style={{ backgroundColor: c[1] }}>
              {' '}
            </span>
          ))}
        </div>
        <div className="label">
          <span>0</span>
          {mapColor.map(c => (
            <span key={c[0]}>{c[2]}</span>
          ))}
        </div>
      </div>
      <Map
        doubleClickZoom={false}
        dragging={false}
        boxZoom={false}
        touchZoom={false}
        zoomControl={false}
        scrollWheelZoom={false}
        attributionControl={false}
        zoomAnimation={false}
        bounds={bbox}
        zoomSnap={0.1}
        className="map"
        style={{ ...style }}
        onClick={mapClickHandler}
      >
        <Pane name="label" />
        {data
          .filter(f => f.adcode !== selected)
          .map(f => {
            return (
              <Polygon
                key={f.adcode}
                positions={f.positions}
                smoothFactor={0}
                fillColor={getColor(f.cSum)}
                fillOpacity={1}
                color="#999999"
                weight={1}
                opacity={1}
                interactive={!!f.cSum}
                onClick={featureClickHandler}
                adcode={f.adcode}
              />
            );
          })}
        {data
          .filter(f => f.adcode === selected)
          .map(f => {
            return (
              <Polygon
                key={f.adcode}
                positions={f.positions}
                smoothFactor={0}
                fillColor={getColor(f.cSum)}
                fillOpacity={1}
                color="#FFFF00"
                weight={2}
                opacity={1}
                interactive={false}
                adcode={f.adcode}
              >
                <Tooltip permanent interactive={false}>
                  {f.nameShort}
                  <br />
                  确诊：{f.cSum}
                  <br />
                  死亡：{f.dSum}
                </Tooltip>
              </Polygon>
            );
          })}
        <Polyline
          positions={NineSegs}
          color="#999999"
          weight={1}
          opacity={1}
          interactive={false}
        />
        {data
          .filter(f => f.cSum > 0)
          .map(f => {
            return (
              <CircleMarker
                key={f.adcode}
                center={f.centroid || f.center}
                stroke={false}
                radius={0}
                interactive={false}
              >
                <Tooltip
                  permanent
                  direction="center"
                  className="label"
                  pane="label"
                >
                  {showNumber ? <AnimNumber value={f.cSum} /> : f.nameShort}
                </Tooltip>
              </CircleMarker>
            );
          })}
      </Map>
      <div className="showNumberSwitch" onClick={toggleShowNumberHandler}>
        <div key="showName" className={showNumber ? 'btn' : 'btn active'}>
          地区名
        </div>
        <div key="showNumber" className={showNumber ? 'btn active' : 'btn'}>
          病例数
        </div>
      </div>
    </div>
  );
}

export default DistMap;
