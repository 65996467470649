import React, { useMemo } from 'react';
import { Flipper, Flipped } from 'react-flip-toolkit';
import { TypeName } from './config';
import AnimNumber from './AnimNumber';
import './DistRank.css';

const staggerConfig = {
  default: {
    reverse: true,
    speed: 1
  }
};

function DistRank({ data, keyConfig = [], selected, onClick }) {
  const flipKey = useMemo(() => data.map(r => r.adcode).join(','), [data]);
  const baseKey = keyConfig[0];
  const leftKey = keyConfig[1];
  const rightKey = keyConfig[2];
  return (
    <div className="DistRank">
      <div className="thead">
        <div className="row">
          <div className="cell">区域</div>
          {keyConfig.map(key => (
            <div key={key} className="cell">
              {TypeName[key]}
            </div>
          ))}
        </div>
      </div>
      <div className="tbody">
        <Flipper flipKey={flipKey} staggerConfig={staggerConfig}>
          {data.map((row, i) => {
            return (
              <Flipped key={row.adcode} flipId={row.adcode} stagger>
                <div
                  className={`row${selected === row.adcode ? ' selected' : ''}`}
                  onClick={() => {
                    onClick(row.adcode);
                  }}
                >
                  <div class="cell">{row.nameShort}</div>
                  {keyConfig.map(key => (
                    <div class="cell" key={key}>
                      <AnimNumber value={row[key]} />
                    </div>
                  ))}
                  {row[baseKey] && row[leftKey] ? (
                    <div
                      class="bar bar-left"
                      style={{
                        width: `${(row[leftKey] * 100) / row[baseKey]}%`
                      }}
                    />
                  ) : null}
                  {row[baseKey] && row[rightKey] ? (
                    <div
                      class="bar bar-right"
                      style={{
                        width: `${(row[rightKey] * 100) / row[baseKey]}%`
                      }}
                    />
                  ) : null}
                </div>
              </Flipped>
            );
          })}
        </Flipper>
      </div>
    </div>
  );
}

export default DistRank;
