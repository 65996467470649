export const TypeName = {
  sAdd: '新增疑似',
  sSum: '累计疑似',
  cAdd: '新增确诊',
  cSum: '累计确诊',
  dAdd: '新增死亡',
  dSum: '累计死亡',
  hAdd: '新增治愈',
  hSum: '累计治愈'
};

export const nameDict = {
  100000: '全国',
  110000: '北京市',
  120000: '天津市',
  130000: '河北省',
  140000: '山西省',
  150000: '内蒙古自治区',
  210000: '辽宁省',
  220000: '吉林省',
  230000: '黑龙江省',
  310000: '上海市',
  320000: '江苏省',
  320100: '南京市',
  320200: '无锡市',
  320300: '徐州市',
  320400: '常州市',
  320500: '苏州市',
  320600: '南通市',
  320700: '连云港市',
  320800: '淮安市',
  320900: '盐城市',
  321000: '扬州市',
  321100: '镇江市',
  321200: '泰州市',
  321300: '宿迁市',
  330000: '浙江省',
  330100: '杭州市',
  330200: '宁波市',
  330300: '温州市',
  330400: '嘉兴市',
  330500: '湖州市',
  330600: '绍兴市',
  330700: '金华市',
  330800: '衢州市',
  330900: '舟山市',
  331000: '台州市',
  331100: '丽水市',
  340000: '安徽省',
  340100: '合肥市',
  340200: '芜湖市',
  340300: '蚌埠市',
  340400: '淮南市',
  340500: '马鞍山市',
  340600: '淮北市',
  340700: '铜陵市',
  340800: '安庆市',
  341000: '黄山市',
  341100: '滁州市',
  341200: '阜阳市',
  341300: '宿州市',
  341500: '六安市',
  341600: '亳州市',
  341700: '池州市',
  341800: '宣城市',
  350000: '福建省',
  360000: '江西省',
  360100: '南昌市',
  360200: '景德镇市',
  360300: '萍乡市',
  360400: '九江市',
  360500: '新余市',
  360600: '鹰潭市',
  360700: '赣州市',
  360800: '吉安市',
  360900: '宜春市',
  361000: '抚州市',
  361100: '上饶市',
  370000: '山东省',
  370100: '济南市',
  370200: '青岛市',
  370300: '淄博市',
  370400: '枣庄市',
  370500: '东营市',
  370600: '烟台市',
  370700: '潍坊市',
  370800: '济宁市',
  370900: '泰安市',
  371000: '威海市',
  371100: '日照市',
  371300: '临沂市',
  371400: '德州市',
  371500: '聊城市',
  371600: '滨州市',
  371700: '菏泽市',
  410000: '河南省',
  410100: '郑州市',
  410200: '开封市',
  410300: '洛阳市',
  410400: '平顶山市',
  410500: '安阳市',
  410600: '鹤壁市',
  410700: '新乡市',
  410800: '焦作市',
  410900: '濮阳市',
  411000: '许昌市',
  411100: '漯河市',
  411200: '三门峡市',
  411300: '南阳市',
  411400: '商丘市',
  411500: '信阳市',
  411600: '周口市',
  411700: '驻马店市',
  419001: '济源市',
  420000: '湖北省',
  420100: '武汉市',
  420200: '黄石市',
  420300: '十堰市',
  420600: '襄阳市',
  420500: '宜昌市',
  421000: '荆州市',
  420800: '荆门市',
  420700: '鄂州市',
  420900: '孝感市',
  421100: '黄冈市',
  421200: '咸宁市',
  421300: '随州市',
  422800: '恩施州',
  429004: '仙桃市',
  429006: '天门市',
  429005: '潜江市',
  429021: '神农架林区',
  430000: '湖南省',
  430100: '长沙市',
  430200: '株洲市',
  430300: '湘潭市',
  430400: '衡阳市',
  430500: '邵阳市',
  430600: '岳阳市',
  430700: '常德市',
  430800: '张家界市',
  430900: '益阳市',
  431000: '郴州市',
  431100: '永州市',
  431200: '怀化市',
  431300: '娄底市',
  433100: '湘西土家族苗族自治州',
  440000: '广东省',
  440100: '广州市',
  440200: '韶关市',
  440300: '深圳市',
  440400: '珠海市',
  440500: '汕头市',
  440600: '佛山市',
  440700: '江门市',
  440800: '湛江市',
  440900: '茂名市',
  441200: '肇庆市',
  441300: '惠州市',
  441400: '梅州市',
  441500: '汕尾市',
  441600: '河源市',
  441700: '阳江市',
  441800: '清远市',
  441900: '东莞市',
  442000: '中山市',
  445100: '潮州市',
  445200: '揭阳市',
  445300: '云浮市',
  450000: '广西壮族自治区',
  460000: '海南省',
  500000: '重庆市',
  510000: '四川省',
  510100: '成都市',
  510300: '自贡市',
  510400: '攀枝花市',
  510500: '泸州市',
  510600: '德阳市',
  510700: '绵阳市',
  510800: '广元市',
  510900: '遂宁市',
  511000: '内江市',
  511100: '乐山市',
  511300: '南充市',
  511400: '眉山市',
  511500: '宜宾市',
  511600: '广安市',
  511700: '达州市',
  511800: '雅安市',
  511900: '巴中市',
  512000: '资阳市',
  513200: '阿坝藏族羌族自治州',
  513300: '甘孜藏族自治州',
  513400: '凉山彝族自治州',
  520000: '贵州省',
  530000: '云南省',
  540000: '西藏自治区',
  610000: '陕西省',
  620000: '甘肃省',
  630000: '青海省',
  640000: '宁夏回族自治区',
  650000: '新疆维吾尔自治区',
  710000: '台湾省',
  810000: '香港特别行政区',
  820000: '澳门特别行政区'
};
export const nameShortDict = {
  100000: '全国',
  110000: '北京',
  120000: '天津',
  130000: '河北',
  140000: '山西',
  150000: '内蒙古',
  210000: '辽宁',
  220000: '吉林',
  230000: '黑龙江',
  310000: '上海',
  320000: '江苏',
  320100: '南京',
  320200: '无锡',
  320300: '徐州',
  320400: '常州',
  320500: '苏州',
  320600: '南通',
  320700: '连云港',
  320800: '淮安',
  320900: '盐城',
  321000: '扬州',
  321100: '镇江',
  321200: '泰州',
  321300: '宿迁',
  330000: '浙江',
  330100: '杭州',
  330200: '宁波',
  330300: '温州',
  330400: '嘉兴',
  330500: '湖州',
  330600: '绍兴',
  330700: '金华',
  330800: '衢州',
  330900: '舟山',
  331000: '台州',
  331100: '丽水',
  340000: '安徽',
  340100: '合肥',
  340200: '芜湖',
  340300: '蚌埠',
  340400: '淮南',
  340500: '马鞍山',
  340600: '淮北',
  340700: '铜陵',
  340800: '安庆',
  341000: '黄山',
  341100: '滁州',
  341200: '阜阳',
  341300: '宿州',
  341500: '六安',
  341600: '亳州',
  341700: '池州',
  341800: '宣城',
  350000: '福建',
  360000: '江西',
  360100: '南昌',
  360200: '景德镇',
  360300: '萍乡',
  360400: '九江',
  360500: '新余',
  360600: '鹰潭',
  360700: '赣州',
  360800: '吉安',
  360900: '宜春',
  361000: '抚州',
  361100: '上饶',
  370000: '山东',
  370100: '济南',
  370200: '青岛',
  370300: '淄博',
  370400: '枣庄',
  370500: '东营',
  370600: '烟台',
  370700: '潍坊',
  370800: '济宁',
  370900: '泰安',
  371000: '威海',
  371100: '日照',
  371300: '临沂',
  371400: '德州',
  371500: '聊城',
  371600: '滨州',
  371700: '菏泽',
  410000: '河南',
  410100: '郑州',
  410200: '开封',
  410300: '洛阳',
  410400: '平顶山',
  410500: '安阳',
  410600: '鹤壁',
  410700: '新乡',
  410800: '焦作',
  410900: '濮阳',
  411000: '许昌',
  411100: '漯河',
  411200: '三门峡',
  411300: '南阳',
  411400: '商丘',
  411500: '信阳',
  411600: '周口',
  411700: '驻马店',
  419001: '济源',
  420000: '湖北',
  420100: '武汉',
  420200: '黄石',
  420300: '十堰',
  420600: '襄阳',
  420500: '宜昌',
  421000: '荆州',
  420800: '荆门',
  420700: '鄂州',
  420900: '孝感',
  421100: '黄冈',
  421200: '咸宁',
  421300: '随州',
  422800: '恩施',
  429004: '仙桃',
  429006: '天门',
  429005: '潜江',
  429021: '神农架',
  430000: '湖南',
  430100: '长沙',
  430200: '株洲',
  430300: '湘潭',
  430400: '衡阳',
  430500: '邵阳',
  430600: '岳阳',
  430700: '常德',
  430800: '张家界',
  430900: '益阳',
  431000: '郴州',
  431100: '永州',
  431200: '怀化',
  431300: '娄底',
  433100: '湘西',
  440000: '广东',
  440100: '广州',
  440200: '韶关',
  440300: '深圳',
  440400: '珠海',
  440500: '汕头',
  440600: '佛山',
  440700: '江门',
  440800: '湛江',
  440900: '茂名',
  441200: '肇庆',
  441300: '惠州',
  441400: '梅州',
  441500: '汕尾',
  441600: '河源',
  441700: '阳江',
  441800: '清远',
  441900: '东莞',
  442000: '中山',
  445100: '潮州',
  445200: '揭阳',
  445300: '云浮',
  450000: '广西',
  460000: '海南',
  500000: '重庆',
  510000: '四川',
  510100: '成都',
  510300: '自贡',
  510400: '攀枝花',
  510500: '泸州',
  510600: '德阳',
  510700: '绵阳',
  510800: '广元',
  510900: '遂宁',
  511000: '内江',
  511100: '乐山',
  511300: '南充',
  511400: '眉山',
  511500: '宜宾',
  511600: '广安',
  511700: '达州',
  511800: '雅安',
  511900: '巴中',
  512000: '资阳',
  513200: '阿坝',
  513300: '甘孜',
  513400: '凉山',
  520000: '贵州',
  530000: '云南',
  540000: '西藏',
  610000: '陕西',
  620000: '甘肃',
  630000: '青海',
  640000: '宁夏',
  650000: '新疆',
  710000: '台湾',
  810000: '香港',
  820000: '澳门'
};
export const subArea = {
  '420000': ['420100']
};

function hsl2rgb(h, m1, m2) {
  let v =
    (h < 60
      ? m1 + ((m2 - m1) * h) / 60
      : h < 180
      ? m2
      : h < 240
      ? m1 + ((m2 - m1) * (240 - h)) / 60
      : m1) * 255;
  return ('00' + Math.round(v).toString(16)).slice(-2);
}

function getRgbStrFromHsl(h0, s0, l0) {
  const h = (h0 % 360) + (h0 < 0) * 360;
  const s = isNaN(h) || isNaN(s0) ? 0 : s0;
  const l = isNaN(l0) ? 0 : l0;
  const m2 = l + (l < 0.5 ? l : 1 - l) * s;
  const m1 = 2 * l - m2;
  return `#${hsl2rgb(h >= 240 ? h - 240 : h + 120, m1, m2)}${hsl2rgb(
    h,
    m1,
    m2
  )}${hsl2rgb(h < 120 ? h + 240 : h - 120, m1, m2)}`;
}

const ColorH0 = 30;
const ColorH1 = -30;
const ColorL0 = 1;
const ColorL1 = 0;
function getHSL(i, n) {
  return getRgbStrFromHsl(
    (ColorH1 * i) / n + (ColorH0 * (n - i)) / n,
    1,
    (ColorL1 * i) / n + (ColorL0 * (n - i)) / n
  );
}

export const mapColor = [
  [0, getHSL(0, 14), '1'],
  [10, getHSL(1, 14), '10'],
  [20, getHSL(2, 14), '20'],
  [50, getHSL(3, 14), '50'],
  [100, getHSL(4, 14), '100'],
  [200, getHSL(5, 14), '200'],
  [500, getHSL(6, 14), '500'],
  [1000, getHSL(7, 14), '1k'],
  [2000, getHSL(8, 14), '2k'],
  [5000, getHSL(9, 14), '5k'],
  [10000, getHSL(10, 14), '10k'],
  [20000, getHSL(11, 14), '20k'],
  [Infinity, getHSL(12, 14), '∞']
];
