import { nameDict, nameShortDict } from './config';
export function loadData() {
  return fetch(`/all.json?${new Date().getTime()}`)
    .then(res => res.json())
    .then(list => {
      const dataByDate = { '100000': {} };
      const dataByAdcode = {};
      list
        .filter(([date]) => date >= '2020-01-20')
        .forEach(
          ([date, adcode, sAdd, sSum, cAdd, cSum, dAdd, dSum, hAdd, hSum]) => {
            const props = {
              date,
              adcode,
              name: nameDict[adcode],
              nameShort: nameShortDict[adcode],
              sAdd,
              sSum,
              cAdd,
              cSum,
              dAdd,
              dSum,
              hAdd,
              hSum
            };
            if (adcode.slice(2) === '0000') {
              if (dataByDate['100000'][date]) {
                dataByDate['100000'][date].push(props);
              } else {
                dataByDate['100000'][date] = [props];
              }
            } else {
              const parent = adcode.slice(0, 2) + '0000';
              if (!dataByDate[parent]) {
                dataByDate[parent] = {};
              }
              if (dataByDate[parent][date]) {
                dataByDate[parent][date].push(props);
              } else {
                dataByDate[parent][date] = [props];
              }
            }
            if (dataByAdcode[adcode]) {
              dataByAdcode[adcode].push(props);
            } else {
              dataByAdcode[adcode] = [props];
            }
          }
        );
      const dateList = {};
      Object.keys(dataByDate).forEach(parent => {
        if (parent !== '100000') {
          dataByAdcode[parent].forEach(props => {
            if (dataByDate[parent][props.date]) {
              dataByDate[parent][props.date].push(props);
            } else {
              dataByDate[parent][props.date] = [props];
            }
          });
        }
        dateList[parent] = Object.keys(dataByDate[parent]).sort();
      });
      return {
        dataByDate,
        dataByAdcode,
        dateList
      };
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}
const R_PI = 6378137 * Math.PI;
const M_S = 1 << 27;
function mercator2lonlat(mercator) {
  const lonlat = [0, 0];
  let x = (mercator[0] / R_PI) * 180;
  let y = (mercator[1] / R_PI) * 180;
  y =
    (180 / Math.PI) *
    (2 * Math.atan(Math.exp((y * Math.PI) / 180)) - Math.PI / 2);
  lonlat[0] = x;
  lonlat[1] = y;
  return lonlat;
}
function decodePosition(p) {
  return mercator2lonlat([
    ((p[0] - M_S) * R_PI) / M_S,
    ((M_S - p[1]) * R_PI) / M_S
  ]);
}
export function loadGeoData(adcode = 100000) {
  return fetch(`/district/an_${adcode}.json`)
    .then(res => res.json())
    .then(data => {
      const { topo } = data;
      const { arcs, transform, objects, bbox } = topo;
      const { sub } = objects;
      const { geometries } = sub;
      const { scale, translate } = transform;
      const arcsDecoded = arcs.map(arc =>
        arc
          .reduce(function(pre, cur) {
            if (pre.length > 0) {
              const last = pre.slice(-1)[0];
              return [...pre, [last[0] + cur[0], last[1] + cur[1]]];
            }
            return [cur];
          }, [])
          .map(p =>
            decodePosition([
              translate[0] + p[0] * scale[0],
              translate[1] + p[1] * scale[1]
            ])
          )
      );
      const geoms = geometries.map(geom => {
        const { properties, arcs: arcList } = geom;
        const { center, centroid, adcode } = properties;
        return {
          adcode: String(adcode),
          positions: arcList.map(r1 =>
            r1.map(r2 =>
              r2.reduce((pre, r3) => {
                if (r3 < 0) {
                  return [
                    ...pre,
                    ...arcsDecoded[~r3]
                      .slice()
                      .reverse()
                      .map(p => [p[1], p[0]])
                  ];
                } else {
                  return [
                    ...pre,
                    ...arcsDecoded[r3].slice().map(p => [p[1], p[0]])
                  ];
                }
              }, [])
            )
          ),
          center: [center[1], center[0]],
          centroid: centroid
            ? [centroid[1], centroid[0]]
            : [center[1], center[0]]
        };
      });
      const bl = decodePosition([bbox[0], bbox[3]]);
      const tr = decodePosition([bbox[2], bbox[1]]);
      return {
        bbox: [
          [bl[1], bl[0]],
          [tr[1], tr[0]]
        ],
        geoms
      };
    })
    .catch(err => {
      console.log(err);
      return {};
    });
}
