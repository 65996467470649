import React, { useState, useEffect } from 'react';
function easeInOutQuad(x) {
  return x < 0.5 ? x * x * 2 : 1 - 2 * (1 - x) * (1 - x);
}

export default function AnimNumber({ initValue, value = 0, timeout = 300 }) {
  const [showValue, setShowValue] = useState(
    typeof value === 'number' ? value : '--'
  );
  const [oldValue, setOldValue] = useState(
    typeof initValue === 'number' ? initValue : value
  );
  useEffect(() => {
    if (value === oldValue) {
      return;
    }
    if (isNaN(value)) {
      setShowValue('--');
      return;
    }
    let handler = undefined;
    let start = undefined;
    function tick(t) {
      if (!start) {
        start = t;
      }
      const dt = t - start;
      const i = easeInOutQuad(Math.min(timeout, dt) / timeout);
      setShowValue(Math.round(value * i + oldValue * (1 - i)));
      if (t - start < timeout) {
        handler = requestAnimationFrame(tick);
      } else {
        setShowValue(value);
        setOldValue(value);
        console.log('end');
        handler = undefined;
      }
    }
    handler = requestAnimationFrame(tick);
    return () => {
      if (handler) {
        console.log('cancel');
        cancelAnimationFrame(handler);
        handler = undefined;
        setOldValue(value);
      }
    };
  }, [timeout, oldValue, value]);
  return <>{showValue}</>;
}
