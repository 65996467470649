import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef
} from 'react';
import { IconContext } from 'react-icons';
import {
  MdFirstPage,
  MdLastPage,
  MdPause,
  // MdStop,
  MdClose,
  MdPlayArrow,
  MdNavigateNext,
  MdNavigateBefore
} from 'react-icons/md';
import DistMap from './DistMap';
import TrendChart from './TrendChart';
import DistRank from './DistRank';
import { loadData, loadGeoData } from './service';
import { nameDict, nameShortDict } from './config';
import AnimNumber from './AnimNumber';
import './App.css';

function getAddStr(add) {
  if (typeof add !== 'number') {
    return '--';
  }
  if (add === 0) {
    return '无新增';
  }
  return `新增${add}例`;
}

const ChartKeyConfig = {
  add: ['sAdd', 'cAdd', 'hAdd', 'dAdd'],
  sum: ['sSum', 'cSum', 'hSum', 'dSum']
};

const TableKeyConfig = ['cSum', 'hSum', 'dSum'];

function App() {
  const [data, setData] = useState(null);
  const [mapOpacity, setMapOpacity] = useState(false);
  const [mapView, setMapView] = useState(true);
  const [viewType, setViewType] = useState('sum');
  const [curAdcode, setCurAdcode] = useState('100000');
  const [mapAdcode, setMapAdcode] = useState('100000');
  const [curDate, setCurDate] = useState('');
  const [geoData, setGeoData] = useState(null);
  const [showRetro, setShowRetro] = useState(false);
  const [playing, setPlaying] = useState(false);
  useEffect(() => {
    loadData().then(data => {
      setData(data);
      setCurDate(data.dateList['100000'].slice(-1)[0]);
    });
  }, []);
  const dateList = useMemo(() => {
    if (data && data.dateList) {
      return data.dateList[mapAdcode] || [];
    }
    return [];
  }, [data, mapAdcode]);
  const dataByAdcode = useMemo(() => data && data.dataByAdcode, [data]);
  const dataByDate = useMemo(
    () => data && data.dataByDate && data.dataByDate[mapAdcode],
    [mapAdcode, data]
  );
  useEffect(() => {
    setMapOpacity(0.2);
    loadGeoData(mapAdcode)
      .then(data => {
        setGeoData(data);
        setMapOpacity(1);
      })
      .catch(() => {
        setMapOpacity(1);
      });
  }, [mapAdcode]);
  useEffect(() => {
    if (!playing) {
      return;
    }
    let idx = dateList.indexOf(curDate);
    if (idx === dateList.length - 1) {
      setPlaying(false);
      return;
    }
    let timeoutId = setTimeout(() => {
      setCurDate(dateList[idx + 1]);
      timeoutId = undefined;
    }, 1000);
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [playing, curDate, dateList]);
  const trendData = useMemo(() => {
    if (!curAdcode || !dataByAdcode) {
      return [];
    }
    return dataByAdcode[curAdcode] || [];
  }, [dataByAdcode, curAdcode]);
  const distributeData = useMemo(() => {
    if (!curDate || !dataByDate) {
      return [];
    }
    return dataByDate[curDate].slice().sort((a, b) => b.cSum - a.cSum) || [];
  }, [dataByDate, curDate]);
  const features = useMemo(() => {
    if (!geoData || distributeData.length === 0) {
      return [];
    }
    const m = distributeData.reduce(
      (pre, cur) => ({ ...pre, [cur.adcode]: cur }),
      {}
    );
    return geoData.geoms.map(geom => ({
      ...m[geom.adcode],
      ...geom
    }));
  }, [geoData, distributeData]);
  const showRetroSwitchHandler = useCallback(() => {
    if (showRetro) {
      setCurDate(dateList.slice(-1)[0]);
      setPlaying(false);
      setShowRetro(false);
    } else {
      setCurDate(dateList[0]);
      setPlaying(true);
      setShowRetro(true);
    }
  }, [showRetro, dateList]);
  const playHandler = useCallback(() => {
    setCurAdcode(mapAdcode);
    if (curDate === dateList.slice(-1)[0]) {
      setCurDate(dateList[0]);
    }
    setPlaying(true);
  }, [curDate, dateList, mapAdcode]);
  const pauseHandler = useCallback(() => {
    setPlaying(false);
  }, []);
  // const stopHandler = useCallback(() => {
  //   setPlaying(false);
  //   setCurDate(dateList.slice(-1)[0]);
  // }, [dateList]);
  const btnGoToStartHandler = useCallback(() => {
    setCurDate(dateList[0]);
  }, [dateList]);
  const btnGoToEndHandler = useCallback(() => {
    setCurDate(dateList.slice(-1)[0]);
  }, [dateList]);
  const btnGoPrevHandler = useCallback(() => {
    let idx = dateList.indexOf(curDate);
    if (idx > 0) {
      setCurDate(dateList[idx - 1]);
    }
  }, [curDate, dateList]);
  const btnGoNextHandler = useCallback(() => {
    let idx = dateList.indexOf(curDate);
    if (idx < dateList.length - 1) {
      setCurDate(dateList[idx + 1]);
    }
  }, [curDate, dateList]);
  const mapClickHandler = useCallback(
    adcode => {
      setCurAdcode(adcode || mapAdcode);
    },
    [mapAdcode]
  );
  const detailHandler = useCallback(() => {
    setMapAdcode(curAdcode);
    if (topRef.current) {
      topRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, [curAdcode]);
  const returnAllHandler = useCallback(() => {
    setMapAdcode('100000');
    setCurAdcode('100000');
    if (topRef.current) {
      topRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, []);
  const returnOverviewHandler = useCallback(() => {
    setCurAdcode(mapAdcode);
  }, [mapAdcode]);
  const topRef = useRef();
  const tableRowClickHandler = useCallback(adcode => {
    setCurAdcode(adcode);
  }, []);
  const toggleShowMapHandler = useCallback(() => {
    setMapView(prev => !prev);
  }, []);
  const todayData = trendData.slice(-1)[0];
  const toggleViewTypeHandler = useCallback(() => {
    setViewType(prev => (prev === 'add' ? 'sum' : 'add'));
  }, []);
  if (!features.length) {
    return (
      <div>
        <div className="mapTitle">新冠肺炎疫情动态</div>
        <div className="loading">数据加载中</div>
      </div>
    );
  }
  return (
    <div className="App">
      <div ref={topRef} className="mapTitle">
        {nameShortDict[mapAdcode]}新冠肺炎疫情
      </div>
      <div className="panel mapPanel">
        {mapView ? (
          <DistMap
            data={features}
            bbox={geoData.bbox}
            selected={curAdcode}
            onClick={mapClickHandler}
            style={{ opacity: mapOpacity }}
          />
        ) : (
          <DistRank
            key={mapAdcode}
            data={distributeData}
            keyConfig={TableKeyConfig}
            selected={curAdcode}
            onClick={tableRowClickHandler}
          />
        )}
        <div className="playControl">
          {mapView ? (
            <div
              key="showTable"
              className="btn btn-left"
              onClick={toggleShowMapHandler}
            >
              查看数据列表
            </div>
          ) : (
            <div
              key="showMap"
              className="btn btn-left"
              onClick={toggleShowMapHandler}
            >
              查看分布地图
            </div>
          )}
          {showRetro ? (
            <IconContext.Provider value={{ className: 'react-icons' }}>
              <div className="btn btn-right btnPlay">
                <div className="playItem icon" onClick={btnGoToStartHandler}>
                  <MdFirstPage />
                </div>
                <div className="playItem icon" onClick={btnGoPrevHandler}>
                  <MdNavigateBefore />
                </div>
                <div className="playItem date">{curDate}</div>
                <div className="playItem icon" onClick={btnGoNextHandler}>
                  <MdNavigateNext />
                </div>
                <div className="playItem icon" onClick={btnGoToEndHandler}>
                  <MdLastPage />
                </div>
                {playing ? (
                  <div className="playItem icon" onClick={pauseHandler}>
                    <MdPause />
                  </div>
                ) : (
                  <div className="playItem icon" onClick={playHandler}>
                    <MdPlayArrow />
                  </div>
                )}
                {/* <div className="playItem icon" onClick={stopHandler}>
                  <MdStop />
                </div> */}
                <div className="playItem icon" onClick={showRetroSwitchHandler}>
                  <MdClose />
                </div>
                {/* <div className="playItem close">关闭</div> */}
                {/* <div className="playItem">结束</div> */}
              </div>
            </IconContext.Provider>
          ) : (
            <div className="btn btn-right" onClick={showRetroSwitchHandler}>
              疫情动态回顾
            </div>
          )}
        </div>
      </div>
      <div className="panel dataPanel">
        <div className="adName">
          {nameDict[curAdcode]}
          <div className="action">
            {curAdcode !== mapAdcode && data.dataByDate[curAdcode] ? (
              <span onClick={detailHandler}>该地区详情</span>
            ) : null}
            {mapAdcode !== '100000' && curAdcode === mapAdcode ? (
              <span onClick={returnAllHandler}>返回全国</span>
            ) : null}
            {mapAdcode !== curAdcode ? (
              <span onClick={returnOverviewHandler}>
                查看{nameDict[mapAdcode]}
              </span>
            ) : null}
          </div>
        </div>
        {todayData ? (
          <>
            <div key={todayData.adcode} className="todayData">
              <div style={{ backgroundColor: '#1890FF' }}>
                <div className="name">确诊</div>
                <div className="sum">
                  <AnimNumber
                    value={todayData.cSum}
                    initValue={
                      todayData.cSum && todayData.cAdd
                        ? todayData.cSum - todayData.cAdd
                        : null
                    }
                  />
                </div>
                <div className="add">{getAddStr(todayData.cAdd)}</div>
              </div>
              <div style={{ backgroundColor: '#2FC25B' }}>
                <div className="name">治愈</div>
                <div className="sum">
                  <AnimNumber
                    value={todayData.hSum}
                    initValue={
                      todayData.hSum && todayData.hAdd
                        ? todayData.hSum - todayData.hAdd
                        : null
                    }
                  />
                </div>
                <div className="add">{getAddStr(todayData.hAdd)}</div>
              </div>
              <div style={{ backgroundColor: '#8543E0' }}>
                <div className="name">死亡</div>
                <div className="sum">
                  <AnimNumber
                    value={todayData.dSum}
                    initValue={
                      todayData.dSum && todayData.dAdd
                        ? todayData.dSum - todayData.dAdd
                        : null
                    }
                  />
                </div>
                <div className="add">{getAddStr(todayData.dAdd)}</div>
              </div>
            </div>
            <div className="datasource">更新时间：{todayData.date}</div>
          </>
        ) : null}
      </div>
      {trendData.length > 0 ? (
        <div className="panel chartPannel">
          <div className="viewTypeTab" onClick={toggleViewTypeHandler}>
            <span className={viewType === 'sum' ? 'active' : ''}>累计趋势</span>
            <span className={viewType === 'add' ? 'active' : ''}>新增趋势</span>
          </div>
          <div className="trendChart">
            <TrendChart data={trendData} keys={ChartKeyConfig[viewType]} />
          </div>
          <div className="datasource">数据来源：国家及各地方卫健委疫情通报</div>
        </div>
      ) : null}
      <div className="copyright">新视野风险管理</div>
    </div>
  );
}

export default App;
