import React, { useMemo } from 'react';
import { Chart, Axis, Geom, Tooltip, Legend } from 'bizgoblin';
import { TypeName } from './config';

const defs = [
  {
    dataKey: 'date',
    type: 'timeCat',
    tickCount: 3
  },
  {
    dataKey: 'value',
    tickCount: 5
  }
];
const TypeColorMap = {
  疑似: '#FACC14',
  确诊: '#1890FF',
  死亡: '#8543E0',
  治愈: '#2FC25B'
};
function typeColor(type) {
  return TypeColorMap[type] || '#666666';
}
function formatLabel(text, index, total) {
  const textCfg = {};
  if (index === 0) {
    textCfg.textAlign = 'left';
  } else if (index === total - 1) {
    textCfg.textAlign = 'right';
  }
  return textCfg;
}
function TrendChart({ data, keys = ['cAdd', 'dAdd', 'hAdd', 'sAdd'] }) {
  const chartData = useMemo(() => {
    let list = [];
    data.forEach(row => {
      keys.forEach(key => {
        list.push({
          date: row.date,
          type: TypeName[key].slice(2),
          value: row[key]
        });
      });
    });
    return list;
  }, [data, keys]);
  return (
    <Chart
      width="100%"
      height="100%"
      data={chartData}
      defs={defs}
      pixelRatio={window.devicePixelRatio}
    >
      <Axis dataKey="date" label={formatLabel} />
      <Axis dataKey="value" />
      <Tooltip showCrosshairs showTitle offsetY={10} />
      <Legend itemWidth={60} align="center" />
      <Geom geom="line" position="date*value" color={['type', typeColor]} />
    </Chart>
  );
}

export default TrendChart;
